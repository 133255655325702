import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { motion } from "framer-motion/dist/framer-motion";
import { setScrollDirection, setScrollUp } from "../../redux/actions/actions";

import { useDispatch, useSelector } from "react-redux";
import SidebarMobile from "../MobileSidebar/SidebarMobile";
import hookResize from "../../../utils/hookResize";
import Sidebar from "../Sidebar/Sidebar";
import './Layout.scss';

const ScrollDirection = () => {
    const dispatch = useDispatch();
    const {scrollDirection} = useSelector(state => state.Reducer);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)) {
                dispatch(setScrollDirection(direction))
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection);
        return () => {
            window.removeEventListener("scroll", updateScrollDirection);
        }
    }, [scrollDirection]);
    return scrollDirection;
};
const Layout = ({children}) => {
    const {resizeWindow, scrollDirection, scrollUp} = useSelector(state => state.Reducer);
    const dispatch = useDispatch();
    const location = useLocation();
    const refLastScrollY = useRef();
    const widht = hookResize();

    let onScroll = (event) => {
        const scrollY = event.currentTarget.scrollTop;
        const direction = scrollY > refLastScrollY?.current ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - refLastScrollY?.current > 1 || scrollY - refLastScrollY?.current < -1)) {
            dispatch(setScrollDirection(direction))
        }
        refLastScrollY.current = scrollY > 0 ? scrollY : 0;
    }

    useEffect(() => {
        if (scrollUp) {
            document.getElementById('element_for_scroll_up').scrollTop = 0;
            dispatch(setScrollUp(false))
        }
    }, [scrollUp])
    return (
        <motion.div
            className={cn("layout", {
                padding: (resizeWindow && location.pathname === "/" && resizeWindow < 991) ||
                    !localStorage.getItem("token"),
                margin_content: resizeWindow < 820 &&
                    (location.pathname.includes("lesson") || location.pathname.includes("generate-course"))
            })}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            {localStorage.getItem("token") &&
                <>
                    <Sidebar/>
                    <SidebarMobile
                        flagHiddenFotter={scrollDirection && scrollDirection === "up"}
                    />
                </>
            }
            <div
                className="content"
                onScroll={onScroll}
                id={'element_for_scroll_up'}
            >
                {children}
            </div>
        </motion.div>
    );
};

export default Layout;