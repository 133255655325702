import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { defaultFuncAndData } from "../../../../utils/defaultData";
import requests from "../../../axios/requests";

import { message, Tooltip } from "antd";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import Button from "../../../components/Button/Button";
import ModalInvite from "./ModalInvite/ModalInvite";

import default_icon from '../../../../images/icons/default_course.png';
import course_feedback from "../../../../images/landing/course_feedback.svg";
import cn from "classnames";

const Banner = ({data, setFlagModalFeedback, flagAuth, profile, quest_id, setTrigger}) => {
    const variable_34 = variableLanguage({keyPage: "landing", keyVariable: "variable_34"});
    const variable_35 = variableLanguage({keyPage: "landing", keyVariable: "variable_35"});
    const variable_36 = variableLanguage({keyPage: "landing", keyVariable: "variable_36"});
    const variable_40 = variableLanguage({keyPage: "landing", keyVariable: "variable_40"});
    const variable_52 = variableLanguage({keyPage: "landing", keyVariable: "variable_52"});
    const variable_55 = variableLanguage({keyPage: "landing", keyVariable: "variable_55"});

    const history = useNavigate();
    const {language} = useSelector(state => state.Reducer);
    const [strBtn, setStrBtn] = useState("");
    const [openModal, setOpenModal] = useState(false);

    let currentButtonStr = async () => {
        if (localStorage.getItem("token")) {
            let haveStudentCourse = false;
            await requests.stages.get_stages(data.id)
                .then(() => {
                    haveStudentCourse = true;
                    if (language === "ru")
                        setStrBtn("Перейти к обучению")
                    else
                        setStrBtn("Go to training")
                })
                .catch(err => console.error(err));
            if (haveStudentCourse)
                return
        }
        if (data?.participateprice || data?.referral) {
            if (language === "ru")
                setStrBtn("Оплатить курс")
            else
                setStrBtn("Pay for course")
        }
        else if (data?.isCorporate && data?.status == 1) {
            if (language === "ru")
                setStrBtn("Ожидает подтверждения")
            else
                setStrBtn("Awaiting confirmation")
        }
        else {
            if (language === "ru")
                setStrBtn("Записаться на курс")
            else
                setStrBtn("Sign up for a course")
        }
    };
    let addRequestApi = () => {
        if (data?.isCorporate) {
            requests.quests
                .add_request_quest({ses: quest_id, userName: profile.fio})
                .then((response) => {
                    if (setTrigger) setTrigger()
                    if (typeof response.data.status !== "undefined" && response.data.status === "already requested") {
                        return message.warning(variable_34);
                    }
                    return message.success(variable_35);
                })
                .catch((e) => {
                    console.log(e.message);
                });
            return;
        }
        if (data?.participateprice) {
            requests.quests.payment_init_post({ses: quest_id.replace("/", "")})
                .then((res) => {
                    if (res.data.payment_url)
                        window.open(res.data.payment_url, '_blank')
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            requests.quests
                .add_request_quest({quest_id: data.id, userName: profile.fio})
                .then((response) => {
                    if (setTrigger) setTrigger()
                    if (typeof response.data.status !== "undefined" && response.data.status === "already requested") {
                        return message.warning(variable_34);
                    }
                    return message.success(variable_52);
                })
                .catch((e) => {
                    console.log(e.message);
                    message.error(variable_36);
                });
        }
    };
    let sendDescribe = () => {
        if (flagAuth) {
            if (data?.referral) {
                requests.quests.get_referral(data.referral)
                    .then(res => {
                        setTimeout(() => {
                            window.open(res.data?.url, "_blank")
                        }, 100)
                    })
                    .catch(err => console.error(err))
                return
            }
            if (strBtn === variable_40)
                history(`/student/course/lesson/${data.id}`);
            else
                addRequestApi();
        } else {
            window.localStorage.setItem("previous_course_link", window.location.href);
            history("/student/auth");
        }
    }

    useEffect(() => {
        console.log("useEffect currentButtonStr", {data})
        if (data !== null)
            currentButtonStr()
    }, [data]);
    return (
        <div
            className={!data?.banner
                ? (data?.speciality === 3 || data?.speciality === 5)
                    ? "preview_course default dark"
                    : "preview_course default"
                : "preview_course"
            }
            style={data?.banner
                ? {background: `url(${data?.banner}) no-repeat 100% 100% / cover`}
                : {background: `url(${defaultFuncAndData.defaultImage(data?.speciality - 1) || default_icon})  no-repeat 100% 100% / cover`}
            }
        >
            <div className="course_figure6"
                 onClick={() => setFlagModalFeedback(true)}
            >
                <img src={course_feedback}/>
            </div>

            {data?.banner_position !== null
                ? <div
                    className={data.banner_position === "right"
                        ? "info_course right"
                        : "info_course"
                    }
                >
                    <div className="title">
                        {window.innerWidth > 820
                            ? <Tooltip
                                overlayInnerStyle={{maxHeight: 400, overflow: "auto"}}
                                placement="right"
                                title={data?.name || ""}
                            >
                                {defaultFuncAndData.spliceStr(data?.name, 50)}
                            </Tooltip>
                            : defaultFuncAndData.spliceStr(data?.name, 50)
                        }
                    </div>
                    <div className="info">
                        {window.innerWidth > 820
                            ? <Tooltip
                                overlayInnerStyle={{maxHeight: 400, overflow: "auto"}}
                                placement="right"
                                title={data?.description || ""}
                            >
                                {defaultFuncAndData.spliceStr(data?.description, 320)}
                            </Tooltip>
                            : defaultFuncAndData.spliceStr(data?.description, 320)
                        }
                    </div>
                    <div className="btn_bottom">
                        <div
                            className={cn("record", {disabled: ["Ожидает подтверждения","Awaiting confirmation"].includes(strBtn)})}
                            onClick={() => sendDescribe()}
                        >
                            {strBtn}
                        </div>
                        {data?.participateprice  > 0 &&
                            <div className="price">
                                {data.participateprice} ₽
                            </div>
                        }
                    </div>
                </div>
                : <>
                    {data?.participateprice > 0 &&
                        <div className="price_">
                            {data.participateprice} ₽
                        </div>
                    }
                    <div
                        className="btn_describe"
                        onClick={() => sendDescribe()}
                    >
                        {strBtn}
                    </div>
                </>
            }
            <Button
                text={variable_55}
                click={() => setOpenModal(true)}
                className={"btn_share"}
                margin={"auto 0 0 0"}
                padding={"13px 15px"}
                maxWidth={"fit-content"}
                borderRadius={"24px"}
                boxShadow={"none"}
            />
            <ModalInvite
                openModalInvite={openModal}
                setOpenModalInvite={setOpenModal}
                data={data}
            />
        </div>
    );
};

export default Banner;