import React, { useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import requests from "../../../axios/requests";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

import { message as messageWarn } from "antd";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

import fake_photos from "../../../../images/fake_photos.png";
import './Step_2.scss';

const Step_2 = (props) => {
    const {fio, email, image, setFio, setEmail, phone, setImage, setCurrent, setName} = props;
    const variables_10 = variableLanguage({keyPage: "authorization", keyVariable: "variables_10"});
    const variables_12 = variableLanguage({keyPage: "authorization", keyVariable: "variables_12"});
    const variables_14 = variableLanguage({keyPage: "authorization", keyVariable: "variables_14"});
    const variables_15 = variableLanguage({keyPage: "authorization", keyVariable: "variables_15"});
    const variables_16 = variableLanguage({keyPage: "authorization", keyVariable: "variables_16"});
    const variables_17 = variableLanguage({keyPage: "authorization", keyVariable: "variables_17"});
    const variables_18 = variableLanguage({keyPage: "authorization", keyVariable: "variables_18"});
    const variables_19 = variableLanguage({keyPage: "authorization", keyVariable: "variables_19"});
    const variables_20 = variableLanguage({keyPage: "authorization", keyVariable: "variables_20"});
    const variables_23 = variableLanguage({keyPage: "authorization", keyVariable: "variables_23"});
    const variables_24 = variableLanguage({keyPage: "authorization", keyVariable: "variables_24"});
    const variables_26 = variableLanguage({keyPage: "authorization", keyVariable: "variables_26"});
    const variables_27 = variableLanguage({keyPage: "authorization", keyVariable: "variables_27"});
    const location = useLocation();

    const [send_email, setSend_email] = useState(true);
    const [render, setRender] = useState(1);
    const imgRef = useRef();

    let onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                customRequest(event.target.files[0]);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    let customRequest = (file) => {
        let fileTypes = "image/*";
        requests.storage.get_yandex_quest_key().then((v) => {
            requests.storage.storage_upload(v.data.key, file, () => {
            }, fileTypes)
                .then(() => {
                    setImage("https://storage.yandexcloud.net/skill-storage/" + v.data.key)
                    imgRef.current.src = "https://storage.yandexcloud.net/skill-storage/" + v.data.key;
                })
                .catch((e) => console.error(e))
                .finally(() => setRender((prev) => prev + 1));
        });
    };
    let registerUser = () => {
        if (!fio) {
            return messageWarn.error(variables_23);
        }
        if (!email) {
            return messageWarn.error(variables_24);
        }
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!reg.test(email)) {
            return messageWarn.error(variables_27);
        }
        if (window.localStorage.getItem("token")
            && window.localStorage.getItem("fio")
            && window.localStorage.getItem("id")
        ) {
            let formatObj = {
                fio,
                email,
                avatar: image,
                send_email,
            };
            requests.users.edit_profile_new(formatObj)
                .then((res) => {
                    setCurrent((prev) => prev + 1);
                })
                .catch((error) => console.log(error, "error edit_profile"));
        } else {
            let objSend = {
                phone,
                fio,
                "avatar": image || "",
                email,
                send_email,
                ...(location?.search
                        ? {invite: location.search.replace("?invite=", "")}
                        : {}
                )
            }
            requests.auth.register_user(objSend).then((v) => {
                window.localStorage.setItem("token", v.data.auth_token);
                window.localStorage.setItem("fio", v.data.fio);
                window.localStorage.setItem("id", v.data.id);
                setName(v.data?.name);
                setCurrent(prev => prev + 1);
            }).catch((e) => {
                console.error(e)
                messageWarn.error(variables_26);
            });
        }
    };
    return (
        <div className={"profile_block"}>
            <div className={"left_block"}>
                <div className="profile_photo">
                    <div style={{display: "flex"}}>
                        <div className="photo">
                            <div>
                                <img
                                    key={image}
                                    src={image || fake_photos}
                                    style={{borderRadius: 16}}
                                    ref={imgRef}
                                />
                                <div className="render"
                                     style={{position: "absolute", opacity: 0}}>{render}</div>
                            </div>
                        </div>
                        <div className="title">
                            <div>{variables_14}</div>
                            <p>
                                {variables_15}<br/>
                                {variables_16}<br/>
                                {variables_17}
                            </p>
                        </div>
                    </div>
                    <div className={"change_button"}>
                        <label htmlFor="photo">
                            {variables_18}
                        </label>
                        <input
                            style={{display: "none"}}
                            id="photo"
                            type="file"
                            accept="image/png, image/jpeg, image/svg"
                            className={"input_photo"}
                            onChange={(e) => onImageChange(e)}
                        />
                    </div>
                </div>
                <div className={"button_next"}>
                    <Button
                        text={variables_12}
                        click={registerUser}
                        margin={"20px 0 15px 0"}
                        padding={"14px"}
                        zIndex={3}
                    />
                </div>
            </div>
            <div className={"right_block"}>
                <div className={"fio"}>
                    <div>
                        <p> {variables_19}</p>
                        <Input
                            value={fio}
                            setValue={setFio}
                            padding={"6px 15px 8px"}
                            autoFocus={true}
                            placeholder={variables_10}
                        />
                    </div>
                    <div>
                        <p>E-mail</p>
                        <Input
                            value={email}
                            setValue={(value) => setEmail(value.replace(/ /g, ""))}
                            padding={"6px 15px 8px"}
                            margin={"5px 0 0 0"}
                            placeholder={variables_10}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13)
                                    registerUser()
                            }}
                        />
                    </div>
                </div>
                <div className="mailing">
                    <span> {variables_20}</span>
                    <label className="switch" htmlFor="checkbox">
                        <input
                            type="checkbox"
                            id="checkbox"
                            checked={send_email}
                            onChange={(e) => setSend_email(e.currentTarget.checked)}
                        />
                        <div className="slider round"></div>
                    </label>
                </div>
                <div className={"button_next"}>
                    <Button
                        text={variables_12}
                        click={registerUser}
                        margin={"20px 0 15px 0"}
                        padding={"8px 9px 9px 9px"}
                        zIndex={3}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step_2;